import React, {useState, useCallback} from 'react';
import classNames from 'classnames';

import Menu from '../menu';
import { APP_MENU } from '../../constants';

import Logo from './LogoLite.svg';
import Cancel from './Cancel.svg';
import MobMenu from './MobMenu.svg';

import AppLink from '../app-link';

import './Header.scss';

export default function Header() {
    const [ mobOpen, setMobOpen ] = useState(false);

    const toggleMobMenu = useCallback(() => {
        setMobOpen(!mobOpen);
    }, [mobOpen]);

    const closeMobMenu = useCallback(() => {
        if (mobOpen) {
            setMobOpen(false);
        }
    }, [mobOpen]);

    return (
        <header className={classNames(
            'container',
            'Header',
            {
                isOpen: mobOpen,
            }
        )}>
            <img className="Header__logo" src={Logo} alt="Simplware"/>
            <div className="Header__menuMob">
                <Menu
                    className="Header__menu"
                    items={APP_MENU}
                    onClose={closeMobMenu}
                />
                <AppLink
                    className="Btn Header__btn"
                    name="Free trial"
                    url="contacts"
                    anchorTo="/"
                    onClick={closeMobMenu}
                />
            </div>
            <button
                className="Header__mobTrigger"
                type="button"
                onClick={toggleMobMenu}
            >
                <img src={mobOpen ? Cancel : MobMenu} alt="Menu"/>
            </button>
        </header>
    )
}