import { Routes, Route } from 'react-router-dom';

import Home from './home';
import PrivacyPolicy from './privacy-policy';

export default function AppRouter() {
    return (
        <div className="container">
            <Routes>
                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicy />}
                />
                <Route element={Home} />
            </Routes>
        </div>
    );
}