import React from 'react';
import ReactDOM from 'react-dom';

import App from './componets/app';
import {reportWebVitals} from './utils';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
