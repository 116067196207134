import React from 'react';

export function OurPartners() {
    return (
        <section className="Section" id="partners">
            <h2 className="Section__title">Our partners</h2>
            <div className="Section__body">
                <p>
                    Simplifying your experience means having pre integrated partners which allows you to easily
                    pick the components which best fit your solution. Our philosophy is that we can NOT be good at
                    everything that's why we have pre integration with a wide range of vendors from the industry
                    on STB, streaming, CDN, DRM and CAS.
                </p>
            </div>
        </section>
    );
}