import React from 'react';
import classNames from 'classnames';

import AppLink from '../app-link';

import './Menu.scss';

export default function Menu(props) {
    const { items, className, onClose } = props;

    return (
        <nav className={classNames('Menu', className)}>
            {items.map((item, index) => <AppLink
                onClick={onClose}
                className="MenuItem"
                key={index}
                {...item}
            />)}
        </nav>
    );
}