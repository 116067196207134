import React, { useMemo } from 'react';

import Menu from '../menu';
import { APP_MENU } from '../../constants';

import Logo from './LogoFooter.svg';
import './Footer.scss';

export default function Footer() {
    const boottomMenu = useMemo(() => ([
        ...APP_MENU,
        {
            name: 'privacy policy',
            url: '/privacy-policy'
        },
    ]), []);

    return (
        <footer className="Footer">
            <div className="container Footer__container">
                <div className="Footer__logo">
                    <img src={Logo} alt="Simplware"/>
                </div>
                <Menu items={boottomMenu} className="Footer__menu"/>
            </div>
        </footer>
    );
}
