import React from 'react';

import './SimplAdmin.scss';
import device from './device.svg';
import managment from './managment.svg';
import drm from './drm.svg';
import statistic from './statistic.svg';
import record from './record.svg';
import adminBg from './admin-bg.svg';
import adminBgSm from './admin-bg-sm.svg';

export function SimplAdmin() {
    return (
        <section className="Section Section__black" id="simpladmin">
            <div className="SimplAdmin">
                <div className="SimplAdmin__container">
                    <div className="SimplAdmin__left">
                        <h2 className="Section__title">
                            SimplAdmin
                        </h2>
                        <div className="Section__body">
                            This is our core backend helping you manage your subscriber base and their packages as well as the
                            different content flows and other elements of your service (streaming, content, DRM).
                        </div>
                        <div className="SimplAdminFeature">
                            <div className="SimplAdminFeature__icon">
                                <img src={managment} alt="Customer Management"/>
                            </div>
                            <div className="SimplAdminFeature__text">
                                Customer Management
                            </div>
                        </div>
                        <div className="SimplAdminFeature">
                            <div className="SimplAdminFeature__icon">
                                <img src={statistic} alt="User Statistic"/>
                            </div>
                            <div className="SimplAdminFeature__text">
                                User Statistic
                            </div>
                        </div>
                        <div className="SimplAdminFeature">
                            <div className="SimplAdminFeature__icon">
                                <img src={device} alt="Device Remote management"/>
                            </div>
                            <div className="SimplAdminFeature__text">
                                Device Remote management
                            </div>
                        </div>
                        <div className="SimplAdminFeature">
                            <div className="SimplAdminFeature__icon">
                                <img src={drm} alt="Multi DRM"/>
                            </div>
                            <div className="SimplAdminFeature__text">
                                Multi DRM
                            </div>
                        </div>
                        <div className="SimplAdminFeature">
                            <div className="SimplAdminFeature__icon">
                                <img src={record} alt="Record Management"/>
                            </div>
                            <div className="SimplAdminFeature__text">
                                Record Management
                            </div>
                        </div>
                    </div>
                    <div className="SimplAdmin__image">
                        <img className="ImgFluid" src={adminBg} alt="circles"/>
                    </div>
                </div>
                <div className="SimplAdmin__imageSm">
                    <img className="ImgFluid" src={adminBgSm} alt="circles"/>
                </div>
            </div>
        </section>
    );
}
