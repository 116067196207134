import React from 'react';

import './Devices.scss';

export function Devices() {
    return (
        <section className="Section" id="devices">
            <div className="Devices">
                <div className="DevicesItem">
                    <div className="DevicesItem__img">
                        <img className="ImgFluid" src="/images/dev/tv.png" alt="Tv"/>
                    </div>
                    <div className="DevicesItem__text">
                        Tizen / WebOS
                    </div>
                </div>

                <div className="DevicesItem">
                    <div className="DevicesItem__img">
                        <img className="ImgFluid" src="/images/dev/appletv.png" alt="Tv"/>
                    </div>
                    <div className="DevicesItem__text">
                        Apple tv
                    </div>
                </div>

                <div className="DevicesItem">
                    <div className="DevicesItem__img">
                        <img className="ImgFluid" src="/images/dev/smartphone.png" alt="Tv"/>
                    </div>
                    <div className="DevicesItem__text">
                        IOS/Android
                    </div>
                </div>

                <div className="DevicesItem">
                    <div className="DevicesItem__img">
                        <img className="ImgFluid" src="/images/dev/boxes.png" alt="Tv"/>
                    </div>
                    <div className="DevicesItem__text">
                        Set Top Boxes Linux&nbsp;/&nbsp;Android
                    </div>
                </div>

                <div className="DevicesItem">
                    <div className="DevicesItem__img">
                        <img className="ImgFluid" src="/images/dev/roku.png" alt="Tv"/>
                    </div>
                    <div className="DevicesItem__text">
                        Roku
                    </div>
                </div>
            </div>
        </section>
    )
}