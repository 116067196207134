import React from 'react';

import {
    Devices,
    Contacts,
    SimplAdmin,
    OurPartners,
    OurApproach,
    HomeDevices,
} from '../../componets/home';

export default function Home() {
    return (
        <div className="HomePage">
            <HomeDevices />
            <OurApproach />
            <SimplAdmin />
            <Devices />
            <OurPartners />
            <Contacts />
        </div>
    );
}