import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink, scroller, animateScroll } from 'react-scroll';

const isMobile = () => window?.innerWidth <= 1024;

export default function AppLink(props) {
    const {name, url, anchorTo, className, onClick} = props;
    const {pathname} = useLocation();
    const isLink = !anchorTo || anchorTo !== pathname;


    if (isLink) {
        return (
            <Link
                className={className}
                to={anchorTo ? `${anchorTo}#${url}` : url}
                onClick={() => {
                    if (anchorTo) {
                        if (onClick instanceof Function && isMobile()) {
                            console.log('click');
                            onClick();
                        }

                        setTimeout(() => {
                            scroller.scrollTo(`${url}`, {
                                duration: 800,
                                delay: 0,
                                smooth: true,
                                offset: -50,
                            });
                        }, 50)
                    } else {
                        setTimeout(() => {
                            animateScroll.scrollToTop();
                        }, 50)
                    }
                }}
            >
                {name}
            </Link>
        );
    }

    return (
        <ScrollLink
            className={className}
            to={url}
            title={name}
            smooth={true}
            offset={-50}
            duration={500}
            onClick={() => {
                if (onClick instanceof Function && isMobile()) {
                    console.log('click');
                    onClick();
                }
            }}
        >
            {name}
        </ScrollLink>
    );
}
