import React from 'react';

import AppLink from '../../app-link';

import './HomeDevices.scss';

export function HomeDevices() {
    return (
        <section className="Section HomeDevices" id="main">
            <div className="HomeDevices__text">
                <div>
                    <h1 className="HomeDevices__title">
                        The easiest way to start and manage your
                        IPTV \ OTT \ VoD projects
                    </h1>
                    <AppLink
                        className="Btn Btn_big HomeDevices__btn"
                        name="Free trial"
                        url="contacts"
                        anchorTo="/"
                    />
                </div>
            </div>
            <div className="HomeDevices__devices">
                <img className="ImgFluid HomeDevices__img" src="/images/Devices.png" alt="Simplware Devices"/>
            </div>
        </section>
    );
}
