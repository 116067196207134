import React from 'react';

export function OurApproach () {
    return (
        <div className="Section Section__columns" id="approach">
            <h2 className="Section__title">
                Our approach
            </h2>
            <div className="Section__body">
                Everything we do is to create a better experience for our customers. Simplware for Telcos
                is a platform aimed at changing the way operators launch TV services.
            </div>
        </div>
    );
}
