import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import './Contacts.scss';

const onContactsSend = ({ name, email, question }, onEnd) => {
    axios({
        method: 'post',
        url: `https://docs.google.com/forms/d/e/1FAIpQLSevcbOFAJkaGZmYwukik_LjfGWU9BLSugKs-JhNWQ-y5al8mQ/formResponse?entry.1180870011=${name}&entry.487336618=${email}&entry.265682410=${question}`,
    }).finally(() => {
        if (onEnd instanceof Function) {
            onEnd();
        }
    });
}

const scheme = Yup.object().shape({
    name: Yup.string().required('Field required'),
    email: Yup.string().email('Invalid email').required('Field required'),
    question: Yup.string().required('Field required'),
});

export function Contacts() {
    const [sentSuccess, setSentSuccess] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            question: '',
        },
        validationSchema: scheme,
        onSubmit: values => {
            onContactsSend(values, () => {setSentSuccess(true)});
        },
    });

    const { errors, touched } = formik;

    useEffect(() => {
        if (sentSuccess) {
            setTimeout(() => {
                formik.resetForm();
                setSentSuccess(false);
            }, 5000);
        }
    }, [sentSuccess]);

    if (sentSuccess) {
        return (
            <section
                className="Contacts"
                id="contacts"
                style={{
                    justifyContent: 'center',
                }}
            >
                <div className="Contacts__title" style={{
                    textAlign: 'center',
                }}>
                    <h2>
                        Thank you for your message!
                        <br/>
                        We will contact you soon.
                    </h2>
                </div>
            </section>
        );
    }

    return (
        <section className="Contacts" id="contacts">
            <div className="Contacts__text">
                <h2 className="Contacts__title">
                    Contact us
                </h2>
                <div className="Contacts__disclaimer">
                    Request a trial to learn how the Simplware can power your IPTV / OTT / VoD project
                </div>
            </div>
            <form className="Contacts__form ContactsForm" noValidate onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
            }}>
                <input
                    id="name"
                    name="name"
                    type="text"
                    className={classNames('FormInput', {
                        error: touched.name && errors.name
                    })}
                    placeholder="Name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
                <input
                    id="email"
                    name="email"
                    type="email"
                    className={classNames('FormInput', {
                        error: touched.email && errors.email
                    })}
                    placeholder="Email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />
                <textarea
                    id="question"
                    name="question"
                    placeholder="Text"
                    className={classNames('FormInput', {
                        error: touched.question && errors.question
                    })}
                    onChange={formik.handleChange}
                    value={formik.values.question}
                />
                <button className="Btn Btn_big Btn_full" type="submit">
                    Send
                </button>
            </form>
        </section>
    )
}
