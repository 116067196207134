const APP_MENU = [
    {
        name: 'main',
        url: 'main',
        anchorTo: '/',
    },
    {
        name: 'our approach',
        url: 'approach',
        anchorTo: '/',
    },
    {
        name: 'simpladmin',
        url: 'simpladmin',
        anchorTo: '/',
    },
    {
        name: 'devices',
        url: 'devices',
        anchorTo: '/',
    },
    {
        name: 'our partners',
        url: 'partners',
        anchorTo: '/',
    },
    {
        name: 'contact us',
        url: 'contacts',
        anchorTo: '/',
    },
];

export { APP_MENU };
