import { BrowserRouter } from "react-router-dom";

import Header from '../header';
import Footer from '../footer';
import AppRouter from '../../pages';

import './App.scss';

export default function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Header />
            <AppRouter />
            <Footer />
        </BrowserRouter>
    </div>
  );
}
